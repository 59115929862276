import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';



function App() {

    return <BrowserRouter>
        <Switch>                      
            <Route exact path='/' component={() => {
                window.location.href = 'https://mail.zoho.com/zm/';
                return null;
            }} />
        </Switch>
    </BrowserRouter>;
}

export default App;
